import React, { useState } from "react"

import { chunk } from "lodash"
import PropTypes from "prop-types"
import { config, useSpring, animated } from "react-spring"
import styled from "styled-components"

import Container from "components/common/Container"
import Section from "components/common/Section"
import Slider from "components/common/Slider"
import useWindowWidth from "helpers/useWindowWidth"
import { mq } from "styles/theme"

import KeyBenefitItem from "./_components"

const PAGE_SIZE = 5

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 5rem 3rem;

  ${({ theme }) => theme.mq.s} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${({ theme }) => theme.mq.xs} {
    grid-template-columns: repeat(1, 1fr);
  }
`

const KeyBenefits = ({ data }) => {
  const windowWidth = useWindowWidth()
  const [isVisible, setIsVisible] = useState(false)
  const slideSpring = useSpring({
    config: config.stiff,
    y: isVisible ? 0 : 250,
    opacity: isVisible ? 1 : 0,
    delay: 300,
  })

  return (
    <Section
      animatedTitle={data.section_title}
      color="graphite"
      onIntersectChange={setIsVisible}
      padding_s="7rem 0 0 0"
    >
      <Container>
        {windowWidth <= mq.xs ? (
          <Slider>
            {chunk(data.Benefit, PAGE_SIZE).map(benefitsChunk => (
              <StyledGrid>
                {benefitsChunk.map(({ content, title, icon }) => (
                  <KeyBenefitItem content={content} icon={icon} title={title} />
                ))}
              </StyledGrid>
            ))}
          </Slider>
        ) : (
          <animated.div
            style={{ transform: slideSpring.y.interpolate(dy => `translate3d(0,${dy}px,0)`), ...slideSpring }}
          >
            <StyledGrid>
              {data.Benefit.map(({ content, title, icon }) => (
                <KeyBenefitItem content={content} icon={icon} title={title} />
              ))}
            </StyledGrid>
          </animated.div>
        )}
      </Container>
    </Section>
  )
}

KeyBenefits.propTypes = {
  data: PropTypes.shape({
    section_title: PropTypes.string.isRequired,
    Benefit: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        title: PropTypes.string,
        icon: PropTypes.shape({}),
      })
    ).isRequired,
  }).isRequired,
}

export default KeyBenefits
