import React, { useEffect, useState } from "react"

import PropTypes from "prop-types"
import { config, useSpring, animated } from "react-spring"

import Container from "components/common/Container"
import Grid from "components/common/Grid"
import Section from "components/common/Section"

import JobOffersItem from "./_components"

const CONTENT_CLASSNAME = "job-offer-content"

const JobOffers = ({ data }) => {
  const [expandedJobOffer, setExpandedJobOffer] = useState(null)
  const [contentHeights, setContentHeights] = useState({})
  const onExpand = id => () => setExpandedJobOffer(prevId => (prevId === id ? null : id))
  const [isVisible, setIsVisible] = useState(false)
  const slideSpring = useSpring({
    config: config.stiff,
    x: isVisible ? 0 : -250,
    opacity: isVisible ? 1 : 0,
    delay: 300,
  })

  const calculateContentHeights = () => {
    const contentWrapper = [...document.querySelectorAll(`.${CONTENT_CLASSNAME}`)]
    setContentHeights(
      contentWrapper.reduce((accumulator, currentElement) => {
        const key = currentElement.dataset.id
        const { height } = currentElement.getBoundingClientRect()
        accumulator[key] = height
        return accumulator
      }, {})
    )
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", calculateContentHeights)
      setTimeout(calculateContentHeights, 0)
      return () => window.removeEventListener("resize", calculateContentHeights)
    }
  }, [])

  return (
    <Section animatedTitle={data.section_title} color="cyan" onIntersectChange={setIsVisible}>
      <Container>
        <Grid columns="1fr" gap="2rem 0">
          {data.offer.map(({ id, title, description, must_have, nice_have, responsibilities }) => (
            <animated.div
              style={{ transform: slideSpring.x.interpolate(dy => `translate3d(${dy}px,0,0)`), ...slideSpring }}
            >
              <JobOffersItem
                key={id}
                contentClassName={CONTENT_CLASSNAME}
                description={description}
                finalHeight={contentHeights[id]}
                id={id}
                isExpanded={id === expandedJobOffer}
                must_have={must_have}
                nice_have={nice_have}
                onExpand={onExpand(id)}
                responsibilities={responsibilities}
                title={title}
              />
            </animated.div>
          ))}
        </Grid>
      </Container>
    </Section>
  )
}

JobOffers.propTypes = {
  data: PropTypes.shape({
    section_title: PropTypes.string.isRequired,
  }).isRequired,
}

export default JobOffers
