import React, { useEffect, useState } from "react"

import PropTypes from "prop-types"
import { useForm } from "react-hook-form"
import { NotificationManager } from "react-notifications"
import styled from "styled-components"

import Button from "components/common/Button"
import Grid from "components/common/Grid"
import Input from "components/common/Input"
import InputFile from "components/common/InputFile"
import Recaptcha from "components/common/Recaptcha"
import textShortener from "helpers/textShortener"

import DialogBase from "../DialogBase"

const StyledForm = styled.form`
  width: 100%;
`

const StyledNameInput = styled.div`
  grid-column: 1/3;

  ${({ theme }) => theme.mq.xs} {
    grid-column: 1/2;
  }
`

const StyledFileNameContainer = styled.div`
  color: ${({ theme }) => theme.color.text.inverted};
  font-weight: 600;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    white-space: nowrap;
    margin-right: 1rem;
  }

  ${({ theme }) => theme.mq.xs} {
    line-height: 5rem;
    grid-row: 2/3;
  }
`

const StyledFooterWrapper = styled.div`
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr 25% 25%;

  ${({ theme }) => theme.mq.xs} {
    grid-template-columns: 1fr;
    max-width: 350px;
    margin: 0 auto;
    grid-gap: 1.5rem;
  }
`

const StyledRemoveAttachment = styled.button`
  cursor: pointer;
  position: relative;
  background: ${({ theme }) => theme.color.cyan_400};
  border-radius: 100%;
  border: none;
  color: ${({ theme }) => theme.color.text.inverted};
  width: 40px;
  height: 40px;
  font-size: 10px;
  z-index: 1;
  box-shadow: 0px 0px 6px 1px rgba(70, 70, 70, 0.25);
`

const StyledMessage = styled.div`
  position: absolute;
  right: 30px;
  bottom: 15px;
  display: ${({ display }) => display};
  margin-right: 2rem;
  font-size: 1.4rem;
  opacity: ${({ opacity }) => opacity};
  color: #662d91;
  transition: opacity 0.2s linear;
  will-change: opacity;
`
// eslint-disable-next-line no-unused-vars
const ApplyDialog = ({ title, onClose }) => {
  const { register, handleSubmit, errors, watch, setValue, reset } = useForm()
  const [fileName, setFileName] = useState(null)
  const [showFormMessage, setShowFormMessage] = useState(false)
  const [sending, setSending] = useState(false)
  const [token, setToken] = useState()
  const fileList = watch(["cv"])

  useEffect(() => {
    const { cv } = fileList
    if (cv && cv.length) {
      const { name } = cv[0]
      setFileName(textShortener(name, 45))
    } else {
      setFileName(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList])

  const removeAttachment = () => {
    setValue("cv", undefined, { shouldValidate: false })
    setFileName(null)
  }

  const onSubmit = data => {
    setSending(true)
    const formData = new FormData()
    formData.append("name", data.name)
    formData.append("email", data.email)
    formData.append("phone", data.phone)
    formData.append("cv", data.cv[0])
    formData.append("g-recaptcha-response", token)

    const requestOptions = {
      method: "POST",
      body: formData,
    }
    fetch(`${process.env.GATSBY_SERVICES_URL}/form/apply`, requestOptions)
      .then(res => res.json())
      .then(resJson => {
        if (resJson.errorMessage) {
          NotificationManager.error(resJson.errorMessage, "Error", 4000)
          setSending(false)
          return
        }
        if (resJson.err) {
          NotificationManager.error("Something went wrong.", "Error", 4000)
          setSending(false)
          return
        }
        reset(formData)
        setShowFormMessage(true)
        setSending(false)
      })
      .catch(() => {
        NotificationManager.error("Something went wrong", "Error", 4000)
        setSending(false)
      })
  }

  return (
    <DialogBase onClose={onClose}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Grid columns="auto auto" columns_xs="auto" gap="1rem 6rem">
          <StyledNameInput>
            <Input error={errors.name} innerRef={register({ required: true })} label="Name" name="name" type="text" />
          </StyledNameInput>
          <Input error={errors.email} innerRef={register({ required: true })} label="Email" name="email" type="email" />
          <Input
            error={errors.phone}
            innerRef={register({ required: true })}
            label="Phone number"
            name="phone"
            type="number"
          />
        </Grid>
        <StyledFooterWrapper>
          <StyledFileNameContainer>
            <p>{textShortener(fileName, 23)}</p>
            {fileName && <StyledRemoveAttachment onClick={removeAttachment}>X</StyledRemoveAttachment>}
          </StyledFileNameContainer>
          <InputFile
            error={errors.cv}
            innerRef={register({ required: "CV file is required" })}
            label="Attach&nbsp;CV"
            name="cv"
          />
          <Button disabled={sending} id="cv-form-click" type="submit">
            Apply
          </Button>
          <StyledMessage display="inline" id="success-message-cv" opacity={showFormMessage ? 1 : 0}>
            Your email has been sent. We reply as quick as we can.
          </StyledMessage>
        </StyledFooterWrapper>
        <Recaptcha onToken={setToken} refresh={sending} />
      </StyledForm>
    </DialogBase>
  )
}

ApplyDialog.defaultProps = {}

ApplyDialog.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ApplyDialog
