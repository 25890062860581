import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledBenefitItem = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-rows: 100px minmax(40px, 60px) auto;
  justify-items: center;
  justify-self: center;
  align-items: center;
  max-width: 320px;

  ${({ theme }) => theme.mq.xxs} {
    max-width: 220px;
  }
`

const StyledBenefitIcon = styled.div`
  width: 80px;
  height: 80px;
  background: ${({ theme }) => theme.color.white_000};
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  position: relative;

  &:after,
  &:before {
    content: "";
    border-radius: 100%;
    background: ${({ theme }) => theme.color.white_000};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    z-index: 1;
    opacity: 0.2;
    width: 100px;
    height: 100px;
  }

  &:after {
    z-index: 2;
    opacity: 0.4;
    width: 90px;
    height: 90px;
  }
`
const StyledBenefitTitle = styled.h3`
  padding-top: 1rem;
  font-size: 1.6rem;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
  color: ${({ theme }) => theme.color.text.inverted};
`
const StyledBenefitText = styled.p`
  text-align: center;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.color.text.inverted};
`

const KeyBenefitItem = ({ icon, title, content }) => (
  <StyledBenefitItem>
    <StyledBenefitIcon>
      <GatsbyImage
        image={getImage(icon.localFile)}
        imgStyle={{ objectFit: "scale-down" }}
        style={{ height: "100%", width: "100%", zIndex: 3 }}
      />
    </StyledBenefitIcon>
    <StyledBenefitTitle>{title}</StyledBenefitTitle>
    <StyledBenefitText>{content}</StyledBenefitText>
  </StyledBenefitItem>
)

KeyBenefitItem.propTypes = {
  icon: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}

export default KeyBenefitItem
