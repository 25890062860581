import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

import GeneralText from "components/common/GeneralText"
import TransitionWrapper from "components/common/Layout"
import Seo from "components/common/Seo"
import SubpageHeader from "components/common/SubpageHeader"
import TrustUs from "components/common/TrustUs"
import JobOffers from "components/pages/careers/JobOffers"
import KeyBenefits from "components/pages/careers/KeyBenefits"
import Recruitment from "components/pages/careers/Recruitment"
import DialogProvider from "context/DialogContext"

const query = graphql`
  query careersQuery {
    strapiCareers {
      page_description
      page_title
      Benefits {
        section_title
        Benefit {
          content
          id
          title
          icon {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 80, placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
      Job_offers {
        offer {
          id
          description
          must_have
          nice_have
          responsibilities
          title
        }
        section_title
      }
      Process {
        recruitment_process {
          id
          content
          title
        }
        section_description
        section_title
      }
    }
  }
`

const Careers = ({ location }) => {
  const { strapiCareers } = useStaticQuery(query)
  return (
    <TransitionWrapper location={location}>
      <Seo seoPageName="Careers" />
      <DialogProvider>
        <>
          <SubpageHeader subpageTitle={strapiCareers.page_title} />
          <GeneralText aboutContentTxt={strapiCareers.page_description} />
          <JobOffers data={strapiCareers.Job_offers} />
          <KeyBenefits data={strapiCareers.Benefits} />
          <Recruitment data={strapiCareers.Process} />
          <TrustUs />
        </>
      </DialogProvider>
    </TransitionWrapper>
  )
}

Careers.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default Careers
