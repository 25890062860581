import React from "react"

import PropTypes from "prop-types"
import styled from "styled-components"

const StyledCloseDialog = styled.button`
  display: none;
  position: absolute;
  cursor: pointer;
  top: 2rem;
  right: 2rem;
  background: ${({ theme }) => theme.color.cyan_400};
  border-radius: 0;
  border: none;
  color: ${({ theme }) => theme.color.text.inverted};
  width: 40px;
  height: 40px;
  font-size: 10px;
  z-index: 1;
  box-shadow: 0px 0px 6px 1px rgba(70, 70, 70, 0.25);
  ${({ theme }) => theme.mq.xs} {
    display: block;
  }
`

const StyledDialogBase = styled.div`
  position: relative;
  ${({ theme }) => theme.gradient.linear.cyan_light_to_dark};
  padding: 5rem;
`

const DialogBase = ({ children, onClose }) => (
  <StyledDialogBase>
    <StyledCloseDialog onClick={onClose}>X</StyledCloseDialog>
    {children}
  </StyledDialogBase>
)

DialogBase.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default DialogBase
