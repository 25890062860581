import React, { createContext, useContext, useEffect, useRef, useState } from "react"

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { drop } from "lodash"
import PropTypes from "prop-types"
import { config, Transition, animated } from "react-spring"
import styled from "styled-components"

import themeObj, { hexToRgba } from "styles/theme"

import ApplyDialog from "../components/dialogs/ApplyDialog"
import GoodLuck from "../components/dialogs/GoodLuck"

const StyledOverlay = styled(animated.div)`
  height: 100vh;
  width: 100vw;
  background: ${({ theme }) => hexToRgba(theme.color.graphite, 0.75)};
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.overlay};
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const GLOBAL_DIALOG = {
  APPLY: "Apply",
  GOOD_LUCK: "GoodLuck",
}

const baseStyles = {
  position: "fixed",
  top: "50%",
  left: "50%",
  width: "100%",
  maxWidth: "800px",
  padding: "5rem 2rem",
  zIndex: themeObj.zIndex.dialog,
}

const DialogContext = createContext({})

const { Provider } = DialogContext

const DialogProvider = ({ children }) => {
  const bodyScrollLockElement = useRef()
  const [dialogQueue, setDialogQueue] = useState([])

  const onClose = () => {
    setDialogQueue(prevDialogQueue => drop(prevDialogQueue))
  }

  const addToGlobalDialogQueue = (dialogs = []) => {
    const dialogArray = Array.isArray(dialogs) ? dialogs : [dialogs]
    setDialogQueue(prevDialogQueue => [...prevDialogQueue, ...dialogArray])
  }

  useEffect(() => {
    if (dialogQueue.length) disableBodyScroll(bodyScrollLockElement)
    else enableBodyScroll(bodyScrollLockElement)
    return () => enableBodyScroll(bodyScrollLockElement)
  }, [dialogQueue])

  const renderDialog = dialog => {
    if (!dialog) return null
    const props = {
      onClose,
      ...dialog.props,
    }
    switch (dialog.type) {
      case GLOBAL_DIALOG.APPLY:
        return <ApplyDialog {...props} />
      case GLOBAL_DIALOG.GOOD_LUCK:
        return <GoodLuck {...props} />
      default:
        return null
    }
  }

  return (
    <Provider value={{ closeGlobalDialog: onClose, addToGlobalDialogQueue, GLOBAL_DIALOG }}>
      {children}
      <Transition
        config={config.stiff}
        enter={{ opacity: 1, y: -50 }}
        from={{ opacity: 0, y: 10 }}
        items={dialogQueue[0]}
        leave={{ opacity: 0, y: 10 }}
      >
        {styles => (
          <>
            <StyledOverlay ref={bodyScrollLockElement} onClick={onClose} style={{ opacity: styles.opacity }} />
            <animated.div
              style={{
                ...baseStyles,
                transform: styles.y.interpolate(dy => `translate3d(-50%, ${dy}%, 0)`),
              }}
            >
              {renderDialog(dialogQueue[0])}
            </animated.div>
          </>
        )}
      </Transition>
    </Provider>
  )
}

DialogProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useDialogContext = () => useContext(DialogContext)

export default DialogProvider
