import React from "react"

import PropTypes from "prop-types"
import styled from "styled-components"

import StepArrow from "assets/svg/careers/recruitment/hr_arrow.svg"

import StepCircle from "../../../../../common/StepCircle"

const StyledArrow = styled.div`
  position: absolute;
  display: block;
  height: 100%;
  left: 100%;
  bottom: 0;
  z-index: 1;

  ${({ theme }) => theme.mq.xs} {
    display: none;
  }
`

const StyledRecruitmentStep = styled.div`
  color: ${({ theme }) => theme.color.text.inverted};
  font-size: 1.5rem;
  display: grid;
  grid-template-rows: 150px auto;
  grid-gap: 1.5rem;
  justify-items: center;
  align-items: center;

  ${({ theme }) => theme.mq.xs} {
    display: grid;
    grid-template-columns: 130px minmax(150px, 280px);
    grid-template-rows: unset;
    grid-gap: 0 3.5rem;
  }

  ${({ theme }) => theme.mq.xxs} {
    grid-template-columns: 90px minmax(150px, 280px);
    grid-gap: 0 2.5rem;
  }
`

const StyledDescription = styled.div`
  height: 100%;
  display: grid;
  grid-gap: 1.5rem;
  grid-template-rows: 60px auto;
  align-self: flex-start;

  ${({ theme }) => theme.mq.xs} {
    grid-template-rows: unset;
    padding-top: 40px;
  }

  h3 {
    text-align: center;
    padding: 0 1rem;
    text-transform: uppercase;
    font-weight: 900;

    ${({ theme }) => theme.mq.xs} {
      text-align: left;
      padding: 0;
    }
  }

  p {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
    text-align: left;
    padding: 4rem 0 4rem 7rem;
    flex-grow: 1;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    background: ${({ color }) => color};

    ${({ theme }) => theme.mq.m} {
      padding: 3rem 0 3rem 6.5rem;
    }

    ${({ theme }) => theme.mq.xs} {
      background: none;
      padding: 0;
    }
  }

  &:nth-of-type(1) {
    p {
      padding: 4rem 0 4rem 4rem;
    }
  }
`

const RecruitmentStep = ({ number, title, content, color, isFirst }) => (
  <StyledRecruitmentStep>
    <StepCircle color={color} isArrow isFirst={isFirst} number={number} />
    <StyledDescription color={color}>
      <h3>{title}</h3>
      <p>
        {content}
        <StyledArrow as={StepArrow} fill={color} />
      </p>
    </StyledDescription>
  </StyledRecruitmentStep>
)

RecruitmentStep.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  isFirst: PropTypes.bool.isRequired,
}

export default RecruitmentStep
