import React from "react"

import PropTypes from "prop-types"
import styled from "styled-components"

import StepArrow from "assets/svg/careers/recruitment/hr_arrow.svg"

const StyledWrapper = styled.div`
  ${({ theme }) => theme.mq.xs} {
    ${({ isSlider, isFirst }) =>
      isSlider &&
      `
        padding: ${isFirst ? "60px 0 20px" : "60px 0 20px"};
      `}
    ${({ isSlider, color, isFirst }) =>
      !isSlider &&
      `
        grid-row: 1/3;
        background-color: ${color};
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: ${isFirst ? "20px 0" : "60px 0 20px"};
        position: relative;
      `}
  }
`

const StyledStepCircle = styled.div`
  background-color: ${({ color }) => color};
  padding: 3rem;
  margin: 1.5rem;
  border-radius: 100%;
  position: relative;
  z-index: 0;

  &:before,
  &:after {
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ color }) => color};
    border-radius: 100%;
  }

  &:before {
    z-index: -1;
    opacity: 0.42;
    width: 120%;
    height: 120%;
  }
  &:after {
    z-index: -2;
    opacity: 0.42;
    width: 135%;
    height: 135%;
  }

  ${({ theme }) => theme.mq.xs} {
    padding: 2.5rem;
  }

  ${({ theme }) => theme.mq.xxs} {
    padding: 1.5rem;
  }
`

const StyledTitle = styled.span`
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ textColor }) => textColor};
  font-size: 3.6rem;
  font-family: ${({ theme }) => theme.font.fatfrank};
`

const StyledArrow = styled.div`
  position: absolute;
  display: none;
  width: 100%;
  left: 0;
  top: 100%;
  z-index: 1;
  transform: rotate(90deg);
  ${({ theme }) => theme.mq.xs} {
    display: block;
  }
`

const StepCircle = ({ number, color, circleColor, textColor, isFirst, isArrow, isSlider = false }) => (
  <StyledWrapper color={color} isFirst={isFirst} isSlider={isSlider}>
    <StyledStepCircle color={circleColor}>
      <StyledTitle textColor={textColor}>{number}</StyledTitle>
    </StyledStepCircle>
    {isArrow && <StyledArrow as={StepArrow} fill={color} />}
  </StyledWrapper>
)

StepCircle.defaultProps = {
  circleColor: "#fff",
  color: "#fff",
  textColor: "#03c2de",
  isArrow: false,
}

StepCircle.propTypes = {
  circleColor: PropTypes.string,
  color: PropTypes.string,
  textColor: PropTypes.string,
  isArrow: PropTypes.bool,
  isFirst: PropTypes.bool.isRequired,
  number: PropTypes.string.isRequired,
}

export default StepCircle
