/* eslint-disable react/no-danger */
import React from "react"

import { marked } from "marked"
import PropTypes from "prop-types"
import styled from "styled-components"

import Button from "components/common/Button"
import { useDialogContext } from "context/DialogContext"

const StyledWrapper = styled.div`
  padding: 3rem 5rem 3rem 13rem;
  background-color: ${({ theme }) => theme.color.cyan_600};
  box-shadow: ${({ theme }) => theme.shadow.light};
  color: ${({ theme }) => theme.color.text.inverted};
  position: relative;
  transition: 0.35s;

  ${({ theme }) => theme.mq.m} {
    padding: 3rem 5rem;
  }

  ${({ theme }) => theme.mq.xs} {
    padding-bottom: ${({ isExpanded }) => (isExpanded ? 0 : "3rem")};
  }
`

const StyledHeader = styled.div`
  font-size: 2rem;
  font-weight: 900;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => theme.mq.xs} {
    flex-direction: column;
    text-align: center;
  }
`

const StyledButton = styled(Button)`
  transition: 0.35s;
  opacity: ${({ isExpanded }) => (isExpanded ? 0 : 1)};
  pointer-events: ${({ isExpanded }) => (isExpanded ? "none" : "unset")};

  ${({ theme }) => theme.mq.xs} {
    margin-top: 2rem;
  }
`

const StyledContentWrapper = styled.div`
  overflow: hidden;
  transition: 0.35s;
  height: ${({ isExpanded, finalHeight }) => (isExpanded ? finalHeight : 0)}px;

  ${({ theme }) => theme.mq.xs} {
    transform: translateY(-4rem);
  }
`

const StyledContent = styled.div`
  display: grid;
  grid-gap: 4rem;
  padding-top: 3rem;
`

const StyledContentBlock = styled.div`
  font-size: 1.6rem;

  h4 {
    margin-bottom: 2.5rem;
    font-weight: 900;
  }

  ul {
    list-style-image: url(/images/right_arrow.svg);
    list-style-position: inside;

    li {
      margin-bottom: 1rem;
    }
  }
`

const StyledBackButton = styled(Button)`
  margin-right: 2rem;
  ${({ theme }) => theme.mq.xs} {
    margin-right: 0;
  }
`

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 1rem 1rem 0;

  ${({ theme }) => theme.mq.xs} {
    padding: 0 1rem 2rem 1rem;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 1.5rem;
    justify-content: stretch;
  }
`

const JobOffersItem = ({
  id,
  title,
  description,
  onExpand,
  isExpanded,
  must_have,
  nice_have,
  responsibilities,
  contentClassName,
  finalHeight,
}) => {
  const { addToGlobalDialogQueue, GLOBAL_DIALOG } = useDialogContext()

  const openDialog = () => {
    addToGlobalDialogQueue([{ type: GLOBAL_DIALOG.APPLY, props: { title } }])
  }

  return (
    <StyledWrapper isExpanded={isExpanded}>
      <StyledHeader isExpanded={isExpanded}>
        <h3>{title}</h3>
        <StyledButton direction="bottom" isExpanded={isExpanded} onClick={onExpand}>
          see more
        </StyledButton>
      </StyledHeader>
      <StyledContentWrapper finalHeight={finalHeight} isExpanded={isExpanded}>
        <StyledContent className={contentClassName} data-id={id}>
          <StyledContentBlock>
            <div dangerouslySetInnerHTML={{ __html: marked.parse(description) }} />
          </StyledContentBlock>
          <StyledContentBlock>
            <h4>YOUR RESPONSIBILITIES:</h4>
            <div dangerouslySetInnerHTML={{ __html: marked.parse(responsibilities) }} />
          </StyledContentBlock>
          <StyledContentBlock>
            <h4>MUST HAVE:</h4>
            <div dangerouslySetInnerHTML={{ __html: marked.parse(must_have) }} />
          </StyledContentBlock>
          <StyledContentBlock>
            <h4>NICE TO HAVE:</h4>
            <div dangerouslySetInnerHTML={{ __html: marked.parse(nice_have) }} />
          </StyledContentBlock>
          <StyledButtonsContainer>
            <StyledBackButton direction="up" onClick={onExpand}>
              back
            </StyledBackButton>
            <Button onClick={openDialog}>Apply</Button>
          </StyledButtonsContainer>
        </StyledContent>
      </StyledContentWrapper>
    </StyledWrapper>
  )
}

JobOffersItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
  must_have: PropTypes.string,
  nice_have: PropTypes.string,
  responsibilities: PropTypes.string,
  contentClassName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  finalHeight: PropTypes.number.isRequired,
}

JobOffersItem.defaultProps = {
  must_have: "",
  nice_have: "",
  responsibilities: "",
}

export default JobOffersItem
