import React, { useState } from "react"

import PropTypes from "prop-types"
import { config, useSpring, animated } from "react-spring"
import styled, { useTheme } from "styled-components"

import Container from "components/common/Container"
import Section from "components/common/Section"

import RecruitmentStep from "./_components/RecrutimentStep"

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ childrenLength }) => `repeat(${childrenLength}, auto)`};
  margin-right: 40px;

  ${({ theme }) => theme.mq.xs} {
    grid-template-columns: auto;
  }
`

const StyledDescription = styled.p`
  color: ${({ theme }) => theme.color.text.inverted};
  font-size: 1.6rem;
  text-align: center;
  padding: 0 3rem 3rem;
`

const colorResolver = (index, color) => {
  switch (index) {
    case 0:
      return color.cyan_300
    case 1:
      return color.cyan_700
    case 2:
      return color.purple_500
    case 3:
      return color.purple_700
    default:
      return color.purple_700
  }
}

const ANIMATION_DELAY = 300

const Recruitment = ({ data }) => {
  const theme = useTheme()
  const [isVisible, setIsVisible] = useState(false)
  const slideSpring = useSpring({ config: config.stiff, y: isVisible ? 0 : 250, opacity: isVisible ? 1 : 0 })
  const slideSpring2 = useSpring({
    config: config.stiff,
    y: isVisible ? 0 : 250,
    opacity: isVisible ? 1 : 0,
    delay: ANIMATION_DELAY,
  })

  return (
    <Section animatedTitle={data.section_title} color="cyan" onIntersectChange={setIsVisible}>
      <Container>
        <animated.div
          style={{ transform: slideSpring.y.interpolate(dy => `translate3d(0,${dy}px,0)`), ...slideSpring }}
        >
          <StyledDescription>{data.section_description}</StyledDescription>
        </animated.div>
        <animated.div
          style={{ transform: slideSpring2.y.interpolate(dy => `translate3d(0,${dy}px,0)`), ...slideSpring2 }}
        >
          <StyledContainer childrenLength={data.recruitment_process.length}>
            {data.recruitment_process.map(({ id, content, title }, index) => (
              <RecruitmentStep
                key={id}
                color={colorResolver(index, theme.color)}
                content={content}
                isFirst={index === 0}
                number={index + 1}
                title={title}
              />
            ))}
          </StyledContainer>
        </animated.div>
      </Container>
    </Section>
  )
}

Recruitment.propTypes = {
  data: PropTypes.shape({
    section_title: PropTypes.string.isRequired,
    section_description: PropTypes.string.isRequired,
    recruitment_process: PropTypes.string.isRequired,
  }).isRequired,
}

export default Recruitment
