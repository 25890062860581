import React from "react"

// import PropTypes from "prop-types"

import DialogBase from "../DialogBase"

const GoodLuck = () => <DialogBase>GoodLuck</DialogBase>

GoodLuck.defaultProps = {}

GoodLuck.propTypes = {}

export default GoodLuck
